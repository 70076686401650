.calculatorDegree {
    background: linear-gradient(75deg,  #fff 20%, rgba(0, 128, 128, 0.5) 100%, #fff 20%);
    padding: 40px 0;
}

.mainPageTitle h1 {
    font-size: 3em;
    color: #242424;
    font-weight: bold;
    line-height: 1.3
}

.imagePageTitle {
    width: 100%;
    border-radius: 25px;
}

.formularyPageTitle h1 {
    font-size: 1.3em;
    color: #242424;
    font-weight: bold;
    line-height: 1.3
}

.selectCustomStyled {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    padding: 5px;
    border: 1px solid #E0E3E7;
}

.selectBorderCustomStyled {
    border: 1px solid rgba(109, 106, 117, 0.2);
}

.selectBorderCustomStyledError {
    border: 1px solid red;
}

.selectInputCustomStyled {
    background-color: transparent !important;
    border-color: transparent !important;
}

.selectInputCustomStyled:focus-visible {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.selectInputCustomStyled:focus-within {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.selectInputCustomStyled:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.selectMenuCustomStyled {
    background-color: rgb(246, 246, 246) !important;
    z-index: 999 !important;
}



.mainAdvantagesContent {
    background: linear-gradient(320deg,  #fff 20%, rgba(0, 128, 128, 0.5) 100%);
    padding: 16px;
}

.btnBeginToRentalize {
    font-weight: bold;
    font-size: 1em;
}


.mainRound {
    position: absolute;
    top: calc((90vw / 2) * -1);
    width: 100%;
    height: 100vw;
    background: linear-gradient(180deg, rgba(76, 201, 240, 0) 50%, rgba(0, 128, 128, 0.35) 100%);
    border-radius: 50%;
    z-index: -1;
}

.round {
    position: relative;
    bottom: 250px;
    left: 530px;
    width: 50px;
    height: 50px;
    background: linear-gradient(180deg, var(--main-winahost-color)0%, rgba(76, 201, 240, 0) 100%);
    border-radius: 50%;
    opacity: .5;
    transform: rotate(40.45deg);
    transform-origin: 0 0;
    z-index: -1;
}

@media (max-width: 499px) {
    .btnBeginToRentalize {
        font-size: .8em;
    }
}

@media (max-width: 899px) {
    .mainPageTitle h1 {
        font-size: 2em;
        text-align: center;
    }

    .mainPageTitle h2 {
        font-size: 1em;
        text-align: center;
    }

    .imagePageTitle {
        width: 100%;
    }
}
